/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

html {
	font-family: "Lato", sans-serif;
}

body {
	margin: 0;
	padding: 0;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.liveContainer {
	display: flex;
	width: 100vw;
	height: 100vh;
}

.btn-block {
	height: 100%;
}

.btn-screen {
	background: #fff;
}

.attendees {
	background: #999999;
	border: 1px solid #999999;
	box-sizing: border-box;
	box-shadow: 0px 0px 4px rgba(255, 78, 69, 0.2);
	border-radius: 2px;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height, or 121% */

	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;

	mix-blend-mode: normal;
	padding: 3px 8px;
	margin-right: 4rem;
}

.clickToStart {
	font-size: 25px;
	background-color: #009ae0;
	color: #ffff;
	margin-top: 20px;
	border-radius: 50%;
	width: 200px;
	height: 200px;
	-webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.12);
	box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.12);
}

.buttonsContainer {
	position: absolute;
	z-index: 2;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0 !important;
	/* -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly; */
	max-height: 25px;
	justify-content: space-between;
	padding: 0 5% 0 2%;
	top: 5vh;
}

.extraInfo {
	display: flex;
}

#toast-container > div {
	opacity: 0.8 !important;
}

.liveNowRed {
	background: #ff4e45;
	border: 1px solid #ff4e45;
	box-sizing: border-box;
	box-shadow: 0px 0px 4px rgba(255, 78, 69, 0.2);
	border-radius: 2px;
	padding: 3px 8px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
}

.liveNowRed:after {
	content: "";
	mix-blend-mode: screen;
	position: absolute;
	top: 8px;
	left: 14px;
	width: 100%;
	height: 16px;
	background: #ff4e45;
	opacity: 0;
	border-radius: 100%;
	transform-origin: 50% 50%;
}

.liveNowRed::after {
	animation: ripple 1.2s ease-out infinite;
	animation-delay: 1s;
}

@keyframes ripple {
	0%,
	35% {
		transform: scale(0);
		opacity: 1;
	}
	50% {
		transform: scale(1.5);
		opacity: 0.8;
	}
	100% {
		opacity: 0;
		transform: scale(4);
	}
}

.hideMob {
	display: flex;
}

.liveScreenShare {
	/* position: absolute;
  top: 0px !important;
  right: -250px !important; */
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
	max-width: unset !important;
	min-width: unset !important;
}

.liveScreenShare > div {
	margin-right: 1rem;
	max-width: unset !important;
	min-width: unset !important;
}

.liveScreenShare > div > a {
	padding: 11px !important;
}

.liveScreenShare > div > button {
	padding: 11px !important;
}

.VIDEOLOGODIV {
	/* position: absolute;
  bottom: 20px !important;
  right: 50px !important; */
	background-color: #fff;
}

.VIDEOLOGOIMG {
	width: 150px;
	height: 50px;
}

#exit-btn {
	font-size: 16px;
}

#rtmp-config-btn {
	font-size: 1.1rem;
}

.loginContainer {
	margin: 0;
	padding: 0;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	width: 100vw;
	height: 100vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: rgb(2, 0, 36);
	background: -o-linear-gradient(
		300deg,
		rgba(2, 0, 36, 1) 0%,
		rgba(1, 40, 84, 1) 0%,
		rgba(0, 123, 186, 1) 18%,
		rgba(0, 154, 224, 1) 55%,
		rgba(0, 212, 255, 1) 100%
	);
	background: linear-gradient(
		150deg,
		rgba(2, 0, 36, 1) 0%,
		rgba(1, 40, 84, 1) 0%,
		rgba(0, 123, 186, 1) 18%,
		rgba(0, 154, 224, 1) 55%,
		rgba(0, 212, 255, 1) 100%
	);
}

.loginContainer__content {
	min-height: 50vh;
	width: 60%;
	background: white;
	-webkit-box-shadow: 2px 6px 21px 0px rgba(0, 0, 0, 0.58);
	box-shadow: 2px 6px 21px 0px rgba(0, 0, 0, 0.58);
	padding: 20px;
	text-align: center;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 20px 1fr;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 20px;
	position: relative;
}

.loginTitle {
	font-size: 40px;
	margin-bottom: 20px;
	line-height: 60px;
}

.loginTitle2 {
	font-size: 40px;
	margin-bottom: 20px;
	line-height: 60px;
}

.loginSubTitle {
	margin-top: 24px;
	font-size: 24px;
	color: #009ae0;
}

.loginFields {
	margin-top: 20px;
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.btnCommon {
	border: none;
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.chatContainerContent {
	/* padding-top:20px; */
	width: 25vw;
	position: absolute;
	top: 5px;
	right: 0;
	bottom: 0;
	height: calc(100vh - 40px);
	background-color: #fff;
	overflow-y: hidden;
	/* z-index: 100000; */
	z-index: 1;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.footerChatMessageText {
	font-size: 14px;
	width: 85%;
	border: 1px solid #dbdbdb;
	padding: 5px 10px;
	border-radius: 4px;
}

.btnMessageSend {
	color: #fff;
	border-radius: 4px;
	padding: 4px 10px;
	border: none;
	cursor: pointer;
}

.footerChatMessageText:focus,
.btnMessageSend:focus {
	outline: none;
}

.btn--bookADemo {
	background: #009ae0;
	color: #fff;
	padding: 8px 16px;
	border-radius: 4px;
}

.form__input {
	margin: 8px 0;
	background: white;
	border: 1px solid #979797;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 8px;
	color: #999999;
	outline: none;
	width: 60%;
}

.imageContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border-right: 1px dashed #dbdbdb;
}

.bookADemoLinkDiv {
	margin-top: 30px;
}

.imageCaption {
	font-size: 20px;
}

.bookADemoLink {
	text-decoration: none;
	color: #009ae0;
	padding: 12px;
	border-radius: 8px;
	margin-top: 20px;
}

.whatsappBtn {
	background-color: #4fce5d;
	text-decoration: none;
}

.whatsappBtn:hover {
	color: #fff;
	text-decoration: none;
}

.copyLinkBtn {
	background-color: #435a64;
	padding: 10px 16px;
	font-size: 12px;
}

.copyLinkInput {
	z-index: -1;
	margin-bottom: 0 !important;
}

.sharingText {
	margin-top: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: space-evenly;
	-ms-flex-pack: space-evenly;
	justify-content: space-evenly;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.btnLive {
	font-size: 25px;
	background-color: #009ae0;
	color: #ffff;
	margin-top: 20px;
	border-radius: 50%;
	width: 140px;
	height: 140px;
	-webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.12);
	box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.12);
}

.SVGLogo {
	height: 50px;
}

.SVGLive {
	width: 100%;
	height: 200px;
	width: 300px;
}

.bookADemoLink {
}

.mTop30 {
	margin-top: 30px;
}

.otpSentNumberDiv {
	margin-top: 20px;
}

.otpSentNumberDiv2 {
	margin-top: 20px;
}

.backButton {
	position: absolute;
	top: 25px;
	left: 10px;
	padding: 0 5px 5px 5px;
	border: 1px solid;
}

#timer {
	font-size: 14px;
	color: rgba(0, 0, 0, 0.7);
	margin-top: 14px;
}

.experiencedDialogue {
	color: #009ae0;
	margin-bottom: 30px;
	font-size: 12px;
}

.durationWatchedDiv {
	margin-right: 4rem;
	padding: 3px 17px;
	border-radius: 4px;
}

.liveScreenShare2 {
	display: none;
}

.trialVersionLine {
	position: absolute;
	bottom: 120%;
	color: #ffffff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 15px;
	left: 0;
	width: 100%;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.4);
	text-align: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.trialVersionButtonDiv {
	margin-left: 15px;
}

.chatContainer {
	width: 25vw;
	position: absolute;
	top: 0px;
	right: 0;
	height: 100vh;
	background-color: #fff;
	overflow-y: hidden;
	/* z-index: 100000; */
	z-index: 1;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.chatContainer--heading {
	padding: 10px;
	text-align: center;
	font-size: 20px;
	border-bottom: 1px solid #009ae0;
	color: #009ae0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #fff;
}

.chatContainerFooter {
	bottom: 5px;
	position: absolute;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	/* padding: 16px 10px; */
	border: 1px solid #dbdbdb;
	background: #fff;
	flex-direction: column;
}

.chatContainerFooter > div {
	width: 100%;
}

.messageContainer {
	max-height: calc(100vh - 120px);
	overflow: hidden;
	overflow-y: scroll;
	font-size: 10px;
	display: flex;
	flex-direction: column;
}

.chatBackButton {
	display: block;
}

.messageBox {
	list-style-type: none;
	padding: 15px;
	height: 80vh;
	overflow-y: scroll;
}

.studentMessageLi {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	margin: 5px 0;
}

.tutorMessageDiv {
	border: 1px solid #009ae0;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 5px 15px;
	border-radius: 8px;
	background: #009ae0;
	font-size: 12px;
	color: #fff;
	max-width: 80%;
	word-wrap: break-word;
	text-align: right;
}

.tutorMessageDivTitle {
	margin-bottom: 5px;
	font-weight: 700;
	font-size: 12px;
}

.tutorMessageDivText {
	font-size: 12px;
	line-height: 1;
}

.tutorMessageLi {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	margin: 5px 0;
}

.studentMessageDiv {
	border: 1px solid #dbdbdb;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 0 20px;
	border-radius: 8px;
	margin: 10px 0;
	background: #dbdbdb;
	font-size: 12px;
	text-align: left;
	max-width: 90%;
	word-wrap: break-word;
}

.studentMessageDivTitle {
	font-size: 12px;
}

.studentMessageDivText {
	font-size: 12px;
}

.tutorMessageDivText {
	color: #fff;
}

.chatMessageInput {
	font-size: 16px;
	padding: 2.5px 5px;
	width: 80%;
	color: #9e9e9e;
}

.fullScreenVideo {
	position: absolute;
	width: 75vw;
	height: 100vh;
}

#full-screen-video {
	position: absolute;
	width: 100vw;
	height: 100vh;
}

.chatBackBtn {
	display: block;
	position: absolute;
	right: 15px;
}

.chatButton {
	display: block;
	font-size: 20px;
	background: #009ae0;
	color: white;
	border-radius: 4px;
	z-index: 9999;
	padding: 11px;
	display: block;
	position: relative;
}

#toast-container > div {
	opacity: 1 !important;
}

.lowerUiBar {
	/* min-height: 50vh;
  max-width: 100vw; */
	position: absolute;
	right: 42vw;
	bottom: 35px !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	z-index: 1;
	width: 45vw;
}

/* .lowerUiBarActions {
  display: flex;
  width: 80%;
  justify-content: space-between;
} */

.sessionInfo {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 20px;
	text-align: center;
}

.tutorName {
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	/* identical to box height */

	color: #ffffff;

	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24);
}

.sessionDuration {
	margin-top: 8px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	/* identical to box height */

	color: #ffffff;

	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24);
}

#add-rtmp-btn {
	margin-left: 10px;
	padding: 0.5rem 1.15rem;
}

.btn-group button i {
	padding-left: 15px;
}

/* .fa-microphone:before {
display:none;
} */

.btn-group-lg > .btn,
.btn-lg {
	font-size: unset;
	line-height: unset;
}

.height50 {
	top: 0;
	height: 50vh !important;
}

.left100 {
	left: -100vw;
}

@media only screen and (max-width: 600px) {
	.tutorMessageDivText {
		font-size: 12px;
	}

	#messageBox {
		max-height: 60vh;
		overflow-y: scroll;
	}

	#full-screen-video {
		width: 100vw;
	}

	.chatBackBtn {
		display: block;
		position: absolute;
		left: 20px;
		font-size: 20px;
		cursor: pointer;
	}

	.chatButton {
		position: absolute;
		left: 5px;
		font-size: 20px;
		background: #009ae0;
		color: white;
		top: 40vh;
		border-radius: 4px;
		z-index: 9999;
		padding: 11px;
		display: block;
		cursor: pointer;
	}
	.trialVersionLine {
		width: 100%;
		font-size: 10px;
		bottom: 120%;
		padding: 15px 0;
		left: 0;
		background: rgba(0, 0, 0, 0.4);
		text-align: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.add-rtmp-btn {
		margin-left: 0 !important;
	}

	#cam-dropdown,
	#mic-dropdown {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.btn--bookADemo {
		font-size: 12px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		text-align: left;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.btn--bookADemo > i {
		font-size: 16px;
	}

	.VIDEOLOGODIV {
		position: relative !important;
		bottom: unset !important;
		right: unset !important;
	}

	#lower-ui-bar {
		margin: 0 !important;
		bottom: 10px !important;
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 0 2% !important;
		-webkit-box-pack: justify !important;
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
		width: 100% !important;
	}

	.durationWatchedDiv {
		margin-left: 0;
	}

	#screen-share-btn-container {
		display: none !important;
	}
	.btn-block {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.buttonsContainer {
		position: absolute;
		z-index: 2;
		width: 100% !important;
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
		margin: 0 !important;
		top: 2% !important;
		max-height: 30px;
	}

	.buttonsContainer div {
		min-width: 20px !important;
		margin-right: 8px !important;
	}

	.buttonsContainer div button {
		height: auto;
	}

	#mic .sharingText > a {
		font-size: 12px;
		padding: 8px;
	}

	.sharingText > button {
		padding: 8px;
		margin-left: 20px;
	}
	.btnLive {
		width: 70px;
		height: 70px;
		font-size: 16px;
	}
	.imageContainer {
		border-right: none;
	}

	.loginContainer__form {
		width: 100%;
	}
	.loginContainer__content {
		min-height: 50vh;
		width: 80%;
		background: white;
		-webkit-box-shadow: 2px 6px 21px 0px rgba(0, 0, 0, 0.58);
		box-shadow: 2px 6px 21px 0px rgba(0, 0, 0, 0.58);
		padding: 20px;
		text-align: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.loginTitle,
	.loginTitle2 {
		font-size: 20px;
		line-height: 20px;
		margin: 20px 0 0 0;
	}
	.loginSubTitle {
		margin-top: 20px;
		font-size: 20px;
		color: #009ae0;
	}

	.form__input {
		width: 80%;
	}

	.mTop30 {
		margin-top: 15px;
	}

	.SVGLogo {
		height: 30px;
	}

	.SVGLive {
		width: 150px;
		height: 150px;
	}

	.experiencedDialogue {
		margin-bottom: 10px;
	}

	.bookADemoLinkDiv {
		margin-top: 10px;
	}
	.liveScreenShare {
		display: none !important;
	}

	.liveScreenShare2 {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-orient: horizontal !important;
		-webkit-box-direction: normal !important;
		-ms-flex-direction: row !important;
		flex-direction: row !important;
		font-size: 16px !important;
		margin-left: 5px;
	}

	.liveScreenShare2 > div {
		margin-right: 15px;
	}

	.copyLinkBtn {
		font-size: 12px !important;
	}

	.copyLinkBtn2 {
		padding: 9px 12px !important;
	}

	.btn-group button i {
		padding-left: 5px !important;
		font-size: 14px !important;
	}

	.VIDEOLOGOIMG {
		width: 100px;
		height: 32px;
	}

	.whatsappBtnBrod {
		padding: 9px 16px !important;
	}

	.rtmp-btn {
		margin: 0 5px !important;
		font-size: 1rem !important;
	}
	#rtmp-btn-container {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
		margin-top: unset !important;
	}

	#rtmp-config-btn {
		margin-right: 10px;
	}

	.chatContainer {
		left: 0;
		right: unset;
		width: 100vw;
		position: absolute;
		height: 50vh;
		bottom: 0%;
		overflow: scroll;
		background: white;
		border-radius: 4px;
		-webkit-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.12);
		box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.12);
	}

	.chatContainer--heading {
		padding: 5px;
		font-size: 14px;
		border-top: 1px solid #009ae0;
		border-right: 1px solid #009ae0;
		border-radius: 0 4px 0 0;
	}

	.chatContainer--content {
		height: 38vh;
		overflow-y: scroll;
	}

	.chatContainer--footer {
		border: none;
		padding: 5px;
		border-top: 1px solid #009ae0;
	}

	#exit-btn {
		padding: 7px;
		font-size: 14px;
	}
}

@media screen and (min-width: 320px) and (max-width: 780px) and (orientation: landscape) {
	.chatButton {
		display: none !important;
	}
	.liveScreenShare > div > a {
		padding: 2px 4px !important;
	}

	.liveScreenShare > div > button {
		padding: 4px 4px !important;
	}

	#video-controls > button {
		padding: 0;
		font-size: 12px;
	}

	#audio-controls > button {
		padding: 0;
		font-size: 12px;
	}

	.btn-group button i {
		padding: 0 2.5px;
	}

	#exit-btn {
		padding: 0;
		font-size: 12px;
	}

	#mic-dropdown {
		padding: 0;
	}

	.dropdown-toggle-split::after {
		font-size: 14px;
	}

	#full-screen-video {
		position: absolute;
		width: 80vw;
		height: 100vh;
	}

	.buttonsContainer {
		width: 80%;
		max-height: 20px;
	}

	.buttonsContainer {
		font-size: 10px;
	}

	#lower-ui-bar {
		width: 80%;
		bottom: 2px !important;
		margin: 0 !important;
		padding: 0 2% 0 5%;
	}

	.trialVersionLine {
		padding: 5px;
		font-size: 8px;
	}

	/* .chatContainer {
  width: 80vw;
}
 */

	.chatContainer--heading {
		padding: 4px;
		font-size: 10px;
	}

	.chatContainer--footer {
		padding: 4px;
		border-top: 1px solid #009ae0;
	}

	.chatContainer--content {
		height: 85vh;
		overflow-y: scroll;
	}

	.footer--chatMessageText {
		font-size: 10px;
		padding: 2.5px 5px;
		width: 80%;
	}

	#rtmp-btn-container {
		max-height: 30px;
	}

	#rtmp-config-btn {
		font-size: 10px;
		padding: 5px;
	}

	#add-rtmp-btn {
		font-size: 10px;
		padding: 5px;
	}

	.VIDEOLOGODIV {
		height: 25px;
	}

	.VIDEOLOGOIMG {
		width: 75px;
		height: 25px;
	}

	#messageBox {
		padding: 5px;
		margin-bottom: 10px;
	}

	.tutorMessageDiv,
	.studentMessageDiv {
		padding: 5px 10px;
	}

	.tutorMessageDivTitle,
	.studentMessageDivText {
		margin-bottom: 2px;
		font-size: 12px;
		font-weight: 600;
	}

	.tutorMessageDivText,
	.studentMessageDivText {
		font-size: 12px;
	}

	.durationWatchedDiv,
	.showMob,
	#liveCount {
		font-size: 12px !important;
	}
}

.cursorPointer {
	cursor: pointer;
}

body .btn:focus {
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

#full-screen-video-iframe {
	position: absolute;
	width: 100vw;
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

#rtmp-btn-container {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: auto;
	z-index: 99;
}

.rtmp-btn {
	bottom: 5vh;
	right: 5vw;
	display: block;
	margin-right: 5px;
	margin-bottom: 5px;
}

.remote-stream-container {
	display: inline-block;
}

#rtmp-controlers {
	height: 100%;
	margin: 0;
}

#local-video {
	position: absolute;
	z-index: 1;
	height: 20vh;
	max-width: 100%;
}

.remote-video {
	position: absolute;
	z-index: 1;
	height: 100% !important;
	width: 80%;
	max-width: 500px;
}

#mute-overlay {
	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0;
	color: #d9d9d9;
	font-size: 2em;
	padding: 0 0 3px 3px;
	display: none;
}

.mute-overlay {
	position: absolute;
	z-index: 2;
	top: 2px;
	color: #d9d9d9;
	font-size: 1.5em;
	padding: 2px 0 0 2px;
	display: none;
}

#no-local-video,
.no-video-overlay {
	position: absolute;
	z-index: 3;
	width: 100%;
	top: 50%;
	color: #cccccc;
	font-size: 2.5em;
	margin: 0 auto;
	display: none;
}

.no-video-overlay {
	width: 80%;
}

#screen-share-btn-container {
	/* z-index: 99;
  */
	display: none;
}

.studentForm {
	position: absolute;
	bottom: 30%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

#studentName {
	border: 1px solid #dbdbdb;
	margin-top: 20px;
	font-size: 20px;
	padding: 10px 20px;
	color: rgba(0, 0, 0, 0.6);
}

#watch-live-btn {
	margin: 0 auto;
	border: 1px solid #009ae0;
	background: #009ae0;
	color: #fff;
}

#watch-live-overlay {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

#external-broadcasts-container {
	max-width: 70%;
	margin: auto 0 5px;
}

#external-broadcasts-container input {
	width: 50%;
}

#external-broadcasts-container button {
	color: #fff;
}

/* #external-broadcasts-container .close-btn {
  padding-bottom: 1.2rem;
} */

#watch-live-btn-text {
	font-size: 24px;
	color: #009ae0;
}
#watch-live-overlay #overlay-container {
	padding: 25px;
	border-radius: 5px;
	position: relative;
	margin: 0 auto;
	top: 0;
	width: 70%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

#watch-live-overlay button {
	display: block;
	/* margin: -50px auto; */
	color: #fff;
	border: 1px solid #009ae0;
	border: none;
	background: #009ae0;
	margin-top: 20px;
}

#watch-live-overlay img {
	height: auto;
	width: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
}

#watch-live-overlay button i {
	padding: 0 10px;
}

.btn-xlg {
	/* padding: 20px 35px; */
	font-size: 30px;
	line-height: normal;
	border-radius: 8px;
}

.drop-mini {
	width: inherit;
	display: inline-block;
}

#external-injest-config label,
#rtmp-config label {
	margin: 0 0.5rem 0.5rem 0;
}

#external-injest-config .row,
#rtmp-config .row {
	margin-left: inherit;
	margin-right: inherit;
}

#addRtmpConfigModal .modal-header,
#external-injest-config .modal-header {
	padding: 0.5rem 1rem 0;
	border-bottom: none;
}

#addRtmpConfigModal .modal-header .close,
#external-injest-config .modal-header .close {
	padding: 0.5rem;
	margin: -0.025rem;
}

#addRtmpConfigModal .modal-body,
#external-injest-config .modal-body {
	padding: 1rem 1rem 0.25rem;
}

#addRtmpConfigModal .modal-footer,
#external-injest-config .modal-footer {
	padding: 0 1rem 0.5rem;
	border-top: none;
}

#pushToRtmpBtn {
	padding: 10px 15px;
}

.close .fa-xs {
	font-size: 0.65em;
}

/* pulsating broadcast button */
.pulse-container {
	height: 100%;
	margin: 5px 10px 0;
}

.pulse-button {
	position: relative;
	/* width: 32px; */
	/* height: 32px; */
	border: none;
	-webkit-box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
	box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
	/* border-radius: 50%; */
	background-color: #e84c3d;
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;
}

.pulse-anim {
	-webkit-animation: pulse 2.25s infinite cubic-bezier(0.66, 0, 0, 1);
	animation: pulse 2.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

@-webkit-keyframes pulse {
	to {
		-webkit-box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
		box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
	}
}
@keyframes pulse {
	to {
		-webkit-box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
		box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
	}
}

/* Respomnsive design */

@media only screen and (max-width: 795px) {
	#watch-live-overlay #overlay-container {
		width: 100%;
	}
}

@media only screen and (max-height: 350px) {
	#watch-live-overlay img {
		height: auto;
		width: 80%;
	}
	#watch-live-overlay #overlay-container {
		top: 60%;
	}
	.btn-xlg {
		font-size: 1rem;
	}
}

@media only screen and (max-height: 400px) {
	.btn-xlg {
		font-size: 1.25rem;
	}
}

@media only screen and (max-width: 1000px) {
	.showMob {
		display: block !important;
	}
	.hideMob {
		display: none !important;
	}
}

@media only screen and (max-width: 400px) {
	.btn-xlg {
		padding: 10px 17px;
	}
}

.centerAligned {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
	background-color: #343a40;
	border-color: #343a40;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 10px;
	border-radius: 6px;
}

.showMob {
	display: none;
}

.dropdown-item {
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	overflow: hidden;
}

.btn-lg {
	padding: 0.5rem 0.5rem;
}

.loader {
	border: 6px solid #f3f3f3;
	border-radius: 50%;
	border-top: 6px solid #3498db;
	width: 50px;
	height: 50px;
	-webkit-animation: spin 1s linear infinite; /* Safari */
	animation: spin 1s linear infinite;
}

.loaderHolder {
	height: 100vh;
	width: 100vw;
	z-index: 100000000000;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	background: white;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.sharingScreenDiv {
	height: 100vh;
	width: 100vw;
	z-index: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	background: white;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

#toastrCount {
	opacity: 0;
	-webkit-animation: fade 2s linear;
	animation: fade 2s linear;
	background: red;
	position: absolute;
	bottom: 5%;
	right: 5%;
}

@-webkit-keyframes fade {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@keyframes fade {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

/* toastrt */

.custom-toast {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.custom-toast img {
	background-size: 50px 50px;
	height: 50px;
	width: 50px;
}

.custom-toast p {
	font-size: 14px;
	padding: 10px;
}

/* ---- end demo code ---- */

#toasts {
	min-height: 0;
	position: fixed;
	right: 20px;
	top: 20px;
	width: 400px;
	z-index: 1000;
}

#toasts .toast {
	background: #d6d8d9;
	border-radius: 3px;
	-webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
	color: rgba(0, 0, 0, 0.6);
	cursor: default;
	margin-bottom: 20px;
	opacity: 0;
	position: relative;
	padding: 20px;
	-webkit-transform: translateY(15%);
	-ms-transform: translateY(15%);
	transform: translateY(15%);
	-webkit-transition: opacity 0.5s ease-in-out,
		-webkit-transform 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
	-o-transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out,
		-webkit-transform 0.5s ease-in-out;
	width: 100%;
	will-change: opacity, transform;
	z-index: 1100;
}

#toasts .toast.success {
	background: #26d68a;
}

#toasts .toast.warning {
	background: #ffa533;
}

#toasts .toast.info {
	background: #2cbcff;
}

#toasts .toast.error {
	background: #f44336;
}

#toasts .toast.show {
	opacity: 1;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: opacity 0.5s ease-in-out,
		-webkit-transform 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
	-o-transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out,
		-webkit-transform 0.5s ease-in-out;
}

#toasts .toast.hide {
	height: 0;
	margin: 0;
	opacity: 0;
	overflow: hidden;
	padding: 0 30px;
	-webkit-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

#toasts .toast .close {
	cursor: pointer;
	font-size: 24px;
	height: 16px;
	margin-top: -10px;
	position: absolute;
	right: 14px;
	top: 50%;
	width: 16px;
}

.hidden {
	display: none;
}

.chatBadge {
	background: rgba(63, 255, 25, 0.9);
	width: auto;
	height: auto;
	margin: 0;
	border-radius: 50%;
	position: absolute;
	top: -4px;
	right: -4px;
	width: 12px;
	height: 12px;
}

.sharingContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 100vh;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
	padding: 0 5%;
}

.width75 {
	width: 75vw !important;
	left: 25vw !important;
}

.clicked {
	background: #dc3545;
}
.btn-cross-desktop {
	color: #fff !important;
	background-color: #c82333 !important;
	border-color: #bd2130 !important;
}

.connectionInfo {
	font-size: 14px;
	padding: 8px;
}
.connectionInfo.error {
	background-color: #ff4058;
	color: white;
}

.connectionInfo.success {
	background-color: #21ba45 !important;
	color: white;
}

.btnLinkRetry {
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	margin-left: 10px;
	color: #fff;
	font-weight: bold;
	font-size: 14px;
}

.disabledChat{
  padding: 1rem;
  text-align: center;
  font-weight: bold;
  background: #F36C3A;
  color: white;
}