.leftControls {
	width: 80%;
	display: flex;
	justify-content: center;
}
.rightControls {
	width: 20%;
	display: flex;
	justify-content: space-between;
}

.commonBtn {
	box-shadow: none;
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}

.Button {
	padding: 3px 12px;
	box-shadow: none;
	border: 1px solid #dbdbdb;
	background: white;
	display: flex;
	align-items: center;
	outline: none;
	border-radius: 4px;
	background: #ffffff;
	box-shadow: 9px 9px 16px rgba(0, 0, 0, 0.12),
		-9px -9px 16px rgba(255, 255, 255, 0.12);
	cursor: pointer;
	margin: 0 2px;
}

.ButtonEnd {
	background: #ff4058;
	color: #fff;
	border: 1px solid #ff4058;
}

.Button > img {
	margin-right: 5px;
}

.selected {
	background-color: #2185d0 !important;
	color: white !important;
}

.selected:hover {
	background-color: #2185d0 !important;
	color: white !important;
}

.lowerUiBarActions {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
