.modalHeading {
    font-weight: 800;
font-size: 16px;
line-height: 24px;
color: rgba(0, 0, 0, 0.87);
}

.InputFields{
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1rem; 
    width: 100%;
    outline: none;
}

.questionBox{
    position: relative;
}

.questionLengthCount{
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.choices{
    margin-top: 1rem;
    margin-bottom: 0.7rem;
}

.choicesInput input{
    margin-bottom: 1.5rem;
}

.PollLength{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 1rem;
}

.PollLength span {
    margin: 1rem 1rem 1rem 0;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
    padding:0.5rem 1rem;
    color: rgba(0, 0, 0, 0.87);
}

.addNew {
    width: 100%;
    display: flex;;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid #009AE0;
    color: #009AE0;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 18px;
}

.pollCreateButton{
    background: #009AE0;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    min-width: 150px;
    margin: 0px auto;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 1rem;
    display: block;
}


.questionBody{
    margin-top: 1.3rem;
    margin-bottom: 1.7rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
}
.options{
    
}
.option{
    border: 1px solid #009AE0;
    box-sizing: border-box;
    border-radius: 40px;
    padding: 1rem;
    display: block;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
}
.timeLeft{
    font-weight: 800;
    font-size: 16px;
    color: #56BE81;
    margin-bottom: 1.7rem;
    margin-top: 1.5rem;
}

.bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 1.6rem;
}

.blue{
    background: #75CAF9;
    color: #000000;
    height: 48px;
    width: 100%;
}

.grey{
    background: #C4CFD6;
    color: rgba(0, 0, 0, 0.87);
    height: 48px;
    width: 100%;
}

.statsBar{
    width: calc(100% - 50px);
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
}

.questionText{
    position: absolute;
    left: 15px;
    max-width: 90%;
    top: 15px;
    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
}


.selected { 
    background: rgba(0, 154, 224, 0.1);
}

/* .bars{
    max-height: 70vh;
    overflow: auto;
} */


.optionLength{
    position: absolute;
    right:1rem;
    top:1rem;
}