.heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 2rem;
}
.body{
    display: flex;
    justify-content: space-between;
}
.leftSide{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.rightSide{
    display: flex;
    flex-direction: column;
}
.rightSide div {
    display: flex;
    align-items: center;
}
.DropdownItems{
    display: flex;
    justify-content: flex-start;
    color: white;
    min-width: 100% !important;
    width: max-content;
    /* max-width: -moz-fit-content !important; */
}
.btnStyle{
    display: flex;
    color: white;
    margin-left: 1rem;
    border: 1px solid white  !important;
    border-radius: 4px;
    width: 17rem !important;
    justify-content: space-between !important;
    padding: 7px !important;
}

.startLiveClass {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4.5rem;
}

.startLiveClass button{
    font-weight: 500;
    font-size: 16px;
    padding: 15px 40px;
    width: 201px;
    height: 48px;
    color: white;
    background: #009AE0;
    border-radius: 4px;
}

.controls{
    justify-content: space-around;
    display: flex;
    margin-top: 1rem;
}

.nameStyle {
    white-space: nowrap;
    max-width: 88%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.video{
    width: 247px;
    height: 150px;
    background: radial-gradient(black, transparent);
}

.selected {
    background-color: #2185d0 !important;
    color: white !important;
  }

  .audioControls{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 1rem;
  }

  .audioControls audio{
      height: 1px;
  }

  .audioControls svg{
    align-self: flex-start;
    margin-left: 3rem;
  }

  #mic-test > div:nth-child(1){
      display: none;
  }
