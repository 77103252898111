.ShareModal {
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	z-index: 100000;
	background-color: rgba(0, 0, 0, 0.65);
	display: grid;
	place-content: center;
	text-transform: none;
}
.ShareModal__Content {
	background-color: white;
	width: 500px;
	height: fit-content;
}
.ShareModal__Content__Top {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 16px 24px;
	border-bottom: 1px solid #e5e5e5;
}
.ShareModal__Content__Top--Title {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */
	display: flex;
	align-items: center;
	color: #000000;
}
.ShareModal__Content__Top--Close {
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	cursor: pointer;
}
.ShareModal__Content__Success {
	border-bottom: 1px solid #e5e5e5;
	padding: 20px 20px 16px;
}
.ShareModal__Content__Success__Row1 {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	/* identical to box height, or 125% */
	display: flex;
	align-items: center;
	color: #000000;
	mix-blend-mode: normal;
	opacity: 0.6;
	margin-bottom: 54px;
}
.ShareModal__Content__Success__Row1 img {
	margin-right: 20px;
}
.ShareModal__Content__Success__Row2 {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */
	display: flex;
	align-items: center;
	color: rgba(0, 0, 0, 0.87);
}
.ShareModal__Content__Body {
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.ShareModal__Content__Body--SocialList {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
}
.ShareModal__Content__Body--SocialList img {
	cursor: pointer;
}
.ShareModal__Content__Body--Or {
	margin: 15px auto;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	color: rgba(0, 0, 0, 0.6);
}
.ShareModal__Content__Body--CopySection {
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 12px 24px;
	display: flex;
	justify-content: space-between;
	width: -webkit-fill-available;
}
.ShareModal__Content__Body--CopySection--Link {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	color: rgba(0, 0, 0, 0.6);
	max-width: 85%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.ShareModal__Content__Body--CopySection--Copy {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	display: flex;
	align-items: center;
	text-align: right;
	color: #009ae0;
	cursor: pointer;
	margin-left: 16px;
}
