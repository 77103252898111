.mp0 {
  margin: 0;
  padding: 0;
}
.mx10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* rooms card style */
.roomsTab,
.chatTab {
  margin-top: 5px;
  overflow: hidden;
  height: 90vh;
  overflow-y: auto;
}

.roomsList {
  list-style: none;
  font-size: 10px !important;
}
.roomUser {
  margin: 16px !important;
  display: flex !important;
  align-items: center;
}
.roomUserPlaceholder {
  margin: 16px !important;
}
.roomUser .avatar {
  /* font-size: 19px; */
  height: 32px !important;
  width: 32px !important;
  margin-right: 8px !important;
  border-radius: 50%;
}
.roomUser .userName {
  font-size: 14px !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  line-height: 19px;
  font-weight: normal !important;
  color: rgba(0, 0, 0, 0.7);
}

.roomUser .infoText {
  font-size: 1.2em !important;
  line-height: 1.4em;
  margin-bottom: 0.2em !important;
  font-weight: normal !important;
  color: rgba(0, 0, 0, 0.6);
}

.roomUser .right {
  float: right;
}

.roomUser .detailContainer {
  flex: 1;
}

.badge {
  padding: 4px 8px;
  font-size: 10px;
  border-radius: 4px;
}
.badge.success {
  background-color: #e4ffe8;
  color: #52b062;
}

.loaderCenter {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
/* rooms card style end */

.ui.attached.menu {
  width: 100% !important;
  max-width: 100% !important;
}

.ui.attached.menu:not(.tabular) {
  border: none;
  border-bottom: 1px solid #dbdbdb;
}

.ui.attached.segment {
  border: none;
  width: 100% !important;
  max-width: 100% !important;
}

.toastDark {
  /* background: #000 !important; */
  font-size: 16px !important;
  color: #fff !important;
  line-height: 15px !important;
}

.recording {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 12px;
  height: 12px;
  font-size: 0;
  background-color: red;
  border: 0;
  border-radius: 12px;
  margin-right: 8px;
  outline: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
  }
  65% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
  }
  90% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
  }
}

.box {
  width: 240px;
  height: 150px;
  position: absolute;
  top: calc(50% - 25px);
  top: -webkit-calc(50% - 25px);
  left: calc(50% - 120px);
  left: -webkit-calc(50% - 120px);
}

.text {
  font-family: "Lato", sans-serif;
  color: #fff;
  font-weight: 300;
  font-size: 45px;
  position: absolute;
  top: calc(50% - 105px);
  top: -webkit-calc(50% - 105px);
  left: calc(50% - 160px);
  left: -webkit-calc(50% - 160px);
  oapcity: 1;
  -webkit-animation: fade-in-out 2.5s infinite;
  -moz-animation: fade-in-out 2.5s infinite;
  -o-animation: fade-in-out 2.5s infinite;
  animation: fade-in-out 2.5s infinite;
}

.comp {
  position: absolute;
  top: 0px;
  width: 80px;
  height: 55px;
  border: 3px solid #fff;
  border-radius: 5px;
}

.comp:after {
  content: "";
  position: absolute;
  z-index: 5;
  top: 19px;
  left: 5px;
  width: 65px;
  height: 18px;
  border-radius: 360px;
  border: 3px solid #fff;
}

.loaderConnecting {
  position: absolute;
  z-index: 5;
  top: 26px;
  left: 12px;
  width: 8px;
  height: 8px;
  border-radius: 360px;
  background: #fff;
  -webkit-animation: loaderConnecting 5s infinite linear 0.5s;
  -moz-animation: loaderConnecting 5s infinite linear 0.5s;
  -o-animation: loaderConnecting 5s infinite linear 0.5s;
  animation: loaderConnecting 5s infinite linear 0.5s;
}

.con {
  position: absolute;
  top: 28px;
  left: 85px;
  width: 100px;
  height: 3px;
  background: #fff;
}

.byte {
  position: absolute;
  top: 25px;
  left: 80px;
  height: 9px;
  width: 9px;
  background: #fff;
  border-radius: 360px;
  z-index: 6;
  opacity: 0;
  -webkit-animation: byte_animate 5s infinite linear 0.5s;
  -moz-animation: byte_animate 5s infinite linear 0.5s;
  -o-animation: byte_animate 5s infinite linear 0.5s;
  animation: byte_animate 5s infinite linear 0.5s;
}

.server {
  position: absolute;
  top: 22px;
  left: 185px;
  width: 35px;
  height: 35px;
  z-index: 1;
  border: 3px solid #fff;
  background: #459bf9;
  border-radius: 360px;
  -webkit-transform: rotateX(58deg);
  -moz-transform: rotateX(58deg);
  -o-transform: rotateX(58deg);
  transform: rotateX(58deg);
}

.server:before {
  content: "";
  position: absolute;
  top: -47px;
  left: -3px;
  width: 35px;
  height: 35px;
  z-index: 20;
  border: 3px solid #fff;
  background: #459bf9;
  border-radius: 360px;
}

.server:after {
  position: absolute;
  top: -26px;
  left: -3px;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  width: 35px;
  height: 40px;
  z-index: 17;
  background: #459bf9;
  content: "";
}

/*Byte Animation*/
@-webkit-keyframes byte_animate {
  0% {
    opacity: 0;
    left: 80px;
  }
  4% {
    opacity: 1;
  }
  46% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    left: 185px;
  }
  54% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 80px;
  }
}

@-moz-keyframes byte_animate {
  0% {
    opacity: 0;
    left: 80px;
  }
  4% {
    opacity: 1;
  }
  46% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    left: 185px;
  }
  54% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 80px;
  }
}

@-o-keyframes byte_animate {
  0% {
    opacity: 0;
    left: 80px;
  }
  4% {
    opacity: 1;
  }
  46% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    left: 185px;
  }
  54% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 80px;
  }
}

@keyframes byte_animate {
  0% {
    opacity: 0;
    left: 80px;
  }
  4% {
    opacity: 1;
  }
  46% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    left: 185px;
  }
  54% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 80px;
  }
}

/*LOADER*/
@-webkit-keyframes loaderConnecting {
  0% {
    width: 8px;
  }
  100% {
    width: 63px;
  }
}

@-moz-keyframes loaderConnecting {
  0% {
    width: 8px;
  }
  100% {
    width: 63px;
  }
}

@-o-keyframes loaderConnecting {
  0% {
    width: 8px;
  }
  100% {
    width: 63px;
  }
}

@keyframes loaderConnecting {
  0% {
    width: 8px;
  }
  100% {
    width: 63px;
  }
}

/*FADE IN-OUT*/
@-webkit-keyframes fade-in-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    oapcity: 1;
  }
}

@-moz-keyframes fade-in-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    oapcity: 1;
  }
}

@-o-keyframes fade-in-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    oapcity: 1;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    oapcity: 1;
  }
}

.showHideButton {
  position: absolute;
  left: 10px;
  top: 10vh;
  padding: 8px;
  box-shadow: none;
  outline: none;
  background: none;
  cursor: pointer;
  visibility: hidden;
}

.activeButton {
  color: #fff;
  border: 1px solid #009ae0;
  background: #009ae0;
}

.disabledButton {
  color: #009ae0;
  background-attachment: #fff;
  border: 1px solid #009ae0;
}

/* .studentVideoContainer:hover {
  background: rgba(0, 0, 0, 0.2);
} */

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.studentVideoContainer:hover > .showHideButtonContainer {
  display: none;
}

.studentVideoContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
  top: 15vh;
  width: 20%;
  left: 0;
  background: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: white;
  visibility: hidden;
}

.left25 {
  left: -25vw;
}

.removeLeft25 {
  left: 0;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: none; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: none;
}

.badge.new {
  color: #52b062;
  border: 1px solid #52b062;
  padding: 1px 8px;
}

#mainVideo > div > video {
  object-fit: contain !important;
}



.settingsTab {
  padding: 16px;
}

.settingsTab__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 0;
}

.settingsTab__item--content {
  width: 70%;
}

.settingsTab__item--content--head {
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.87);

  mix-blend-mode: normal;
}

.settingsTab__item--content--desc {
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.6);
}