.toaster{
    margin: 0.5rem;
}

.toasters{
    position: fixed;
    max-width: 499px;
    min-width: 20vw;
    margin: 2rem auto; /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    z-index: 100000000;
    max-height: 50vh;
    overflow: hidden;
    /* -moz-box-shadow: inset 0 -10px 10px -10px #000000;
    -webkit-box-shadow: inset 0 -10px 10px -10px #000000;
    box-shadow: inset 0 -10px 10px -10px #000000; */
}

.toaster{
    max-width: 499px;
    min-width: 20vw;
    text-align: center;
    padding: 1rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
}

.warning{
    background: #F36C3A;
    color: white;
}

.success{
    background: green;
    color: white;
}

.error{
    background: red;
    color: white;
}

.info{
    background: #616161;
    color: white;
}